// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from '../firebase'
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import "react-datepicker/dist/react-datepicker.css";

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading.web";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Share from "../../blocks/share/src/Share";
import Search from "../../blocks/search/src/Search.web";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword.web";
import ResetPassword from "../../blocks/forgot-password/src/ResetPassword.web";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Settings from "../../blocks/settings2/src/Settings.web";
import Cfargooglestreetviewinlaycontent from "../../blocks/cfargooglestreetviewinlaycontent/src/Cfargooglestreetviewinlaycontent.web";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import CreateComment from "../../blocks/comments/src/CreateComment";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Subscriptionbilling2 from "../../blocks/subscriptionbilling2/src/Subscriptionbilling2";
import Notifications from "../../blocks/notifications/src/Notifications";
import NotificationsWeb from "../../blocks/notifications/src/Notifications.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Cfgooglestreetviewapiintegration from "../../blocks/cfgooglestreetviewapiintegration/src/Cfgooglestreetviewapiintegration";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import LandingPage from "../../blocks/landingpage/src/LandingPage.web";
import Blockedusers from "../../blocks/blockedusers/src/Blockedusers";
import AddBlockeduser from "../../blocks/blockedusers/src/AddBlockeduser";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import Captcha from "../../blocks/captcha/src/Captcha";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Likeapost2 from "../../blocks/likeapost2/src/Likeapost2";
import EmailAccountRegistrationWeb from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import Customisableuserprofiles from '../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web';
import AddressList from '../../blocks/customisableuserprofiles/src/AddressList.web';
import FriendsProfile from '../../blocks/customisableuserprofiles/src/FriendsProfile.web';
import FriendsList from '../../blocks/customisableuserprofiles/src/FriendsList.web';
import ContentModeration from '../../blocks/contentmoderation/src/ContentModeration.web';
import NewsFeed from '../../blocks/newsfeed/src/NewsFeed.web';
import Toast from '../../components/src/CustomSnackbar.web';
import { getStorageData, setStorageData } from '../../framework/src/Utilities';
import { baseURL } from "../../framework/src/config.js"

const routeMap = {
  NotificationsWeb:{
    component:NotificationsWeb,
   path:"/NotificationsWeb"},
  NewsFeed:{
    component:NewsFeed,
   path:"/NewsFeed"},
  FriendsList:{
    component:FriendsList,
   path:"/FriendsList"},
  FriendsProfile:{
    component:FriendsProfile,
   path:"/FriendsProfile"},
  AddressList:{
    component:AddressList,
   path:"/AddressList"},
Customisableuserprofiles:{
 component:Customisableuserprofiles,
path:"/Customisableuserprofiles"},
EmailAccountRegistrationWeb:{
  component:EmailAccountRegistrationWeb,
 path:"/EmailAccountRegistrationWeb"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Share:{
 component:Share,
path:"/Share"},
Search:{
 component:Search,
path:"/Search"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ResetPassword:{
  component:ResetPassword,
 path:"/ResetPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Settings:{
 component:Settings,
path:"/Settings"},
Cfargooglestreetviewinlaycontent:{
 component:Cfargooglestreetviewinlaycontent,
path:"/Cfargooglestreetviewinlaycontent"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Subscriptionbilling2:{
 component:Subscriptionbilling2,
path:"/Subscriptionbilling2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Cfgooglestreetviewapiintegration:{
 component:Cfgooglestreetviewapiintegration,
path:"/Cfgooglestreetviewapiintegration"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
Blockedusers:{
 component:Blockedusers,
path:"/Blockedusers"},
AddBlockeduser:{
 component:AddBlockeduser,
path:"/AddBlockeduser"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Captcha:{
 component:Captcha,
path:"/Captcha"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Likeapost2:{
 component:Likeapost2,
path:"/Likeapost2"},

  Home: {
    component: EmailAccountLoginBlock,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  ContentModeration:{
    component: ContentModeration,
   path:"/ContentModeration"},
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastOpen: false,
      toastMessage: '',
      toastDescription: '',
      toastSeverity: '',
    };
    this.listenForMessages = this.listenForMessages.bind(this);
  }

  async componentDidMount() {
    const messaging = firebase.messaging();
    this.listenForMessages(messaging);
    this.initializeNotification(messaging);
  }
  
  async initializeNotification(messaging) {
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        await this.getFCMToken(messaging);
      } else if (permission === 'default') {
        this.handlePermissionChanges(messaging);
      }
    } catch (error) {
      this.showToast('error', String(error));
    }
  }
  
  handlePermissionChanges(messaging) {
    navigator.permissions.query({ name: 'notifications' }).then((permissionStatus) => {
      permissionStatus.onchange = async () => {
        if (permissionStatus.state === 'granted') {
          await this.getFCMToken(messaging);
        }
      };
    });
  }
  
  async getFCMToken(messaging) {
    try {
      const authToken = await getStorageData('token');
      const token = await messaging.getToken();
      const response = await fetch(
        `${baseURL}/account_block/accounts/update_device_id`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json', token: authToken },
          body: JSON.stringify({ device_id: token }),
        }
      );
      const responseData = await response.json();
      if (responseData.errors?.[0]?.token) {
        const redirected = await getStorageData('redirected');
        const path = new URL(document.URL).pathname;
        if (!redirected && !(path === '/' || path.includes('/EmailAccountLoginBlock')||path.includes('/EmailAccountRegistrationWeb'))) {
          await setStorageData('redirected', 'true');
          window.open('EmailAccountLoginBlock', '_self');
        }
      }
    } catch (error) {
      this.showToast('error', String(error));
    }
  }
  
  listenForMessages(messaging) {
    messaging.onMessage(({ notification }) => {
      const { title, body } = notification || {};
      if (Notification.permission === 'granted') {
        this.showToast('success', title, body);
      }
    });
  }
  
  showToast(severity, message, description = '') {
    this.setState({
      toastOpen: true,
      toastMessage: message,
      toastDescription: description,
      toastSeverity: severity,
    });
  }
  
  handleToastClose = () => this.setState({ toastOpen: false });

  render() {
    const defaultAnalytics = firebase.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <BuilderProvider>
        <View>
          {WebRoutesGenerator({ routeMap })}
          <ModalContainer />
          <Toast
          open={this.state.toastOpen}
          message={this.state.toastMessage}
          description={this.state.toastDescription}
          onClose={this.handleToastClose}
          severity={this.state.toastSeverity}
          duration={5000}
          position={{ vertical: "top", horizontal: "right" }}
          />
        </View>
      </BuilderProvider>
    );
  }
}

export default App;