import React from "react";

// Customizable Area Start
import { Grid, styled, Typography } from "@material-ui/core";
import Header from "../../../blocks/landingpage/src/Header.web";
import { aboutUs, CopyRight, PhoneIcon, MailIcon } from "./assets";
import CustomFontVariant from "../../../components/src/CustomFontVariant.web";
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div>
        <Header activeTab="2" navigation={this.props.navigation} id={""} isAuthenticated={Boolean(this.state.isLoggedIn)} />
        <MissionContainer>
          <Grid container spacing={2} style={{ height: "100%" }} >
            <Grid item xs={4} style={{ height: "100%" }}>
              <MissionTitle>Our mission</MissionTitle>
            </Grid>
            <Grid item xs={8}>
              <MissionText>
                Lorem ipsum dolor sit amet consectetur. Adipiscing donec risus tincidunt suspendisse nec vitae scelerisque eget integer. Ut pulvinar arcu arcu orci viverra est lobortis nulla mauris. Euismod phasellus sit volutpat id ante in nibh. In nec eget enim at id fermentum. Amet enim enim adipiscing faucibus eros consectetur amet augue lectus. Et malesuada in vel est quam neque. Risus tempus faucibus imperdiet lectus vel
              </MissionText>
            </Grid>
          </Grid>
        </MissionContainer>
        <AboutUsContent>
          <Grid container spacing={2} style={{ height: "351px", display: 'flex', justifyContent: "center", marginTop: '50px' }} >
            <Grid item xs={6} style={{ height: "100%", display: 'flex', justifyContent: "center", margin: 'auto' }}>
              <div style={{ display: 'flex', flexDirection: "column" }}>
                <ContentTitle>What we do?</ContentTitle>
                <ContentText>
                  Lorem ipsum dolor sit amet consectetur. Aliquam parturient id nulla vestibulum cursus morbi congue est nullam. Ullamcorper aliquam nisl dolor interdum semper libero nulla. Auctor aliquet dui porttitor vel aliquet tellus ipsum neque aliquam. Pellentesque dictum consectetur quis odio ac nunc consequat. Massa ipsum nunc suspendisse tincidunt a dolor egestas quis. Dictumst duis dictum ut est. Habitant ipsum in aliquam ornare felis aliquam eu elementum. Sit sed eget posuere risus felis.
                </ContentText>
              </div>
            </Grid>
            <Grid item xs={6}>
              <MissionTitle>
                <img src={aboutUs} alt="about us content" />
              </MissionTitle>
            </Grid>
          </Grid>
          <DividerBlock>
            <Divider />
            <Divider />
          </DividerBlock>
        </AboutUsContent>
        <AboutUsFooter>
          <Grid container spacing={2} style={{ height: "144px", width: "1130px" }} >
            <Grid item xs={4} style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <FooterText>Home Guestbooking</FooterText>
              <FooterText>Garage Sales</FooterText>
              <FooterText>Time Machine</FooterText>
            </Grid>
            <Grid item xs={4} style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
              <div>
                <FooterText>Home Guestbooking</FooterText>
                <FooterText>Garage Sales</FooterText>
                <FooterText>Time Machine</FooterText>
              </div>
            </Grid>
            <Grid item xs={4} style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "end" }}>
              <FooterTextRight style={{width: "210px"}}>455 West Orchard Street Kings Mountain, NC 280867</FooterTextRight>
              <FooterTextRight style={{fontWeight: "700"}}><img src={PhoneIcon} alt="PhoneIcon" /> +088 (246) 642-27-10</FooterTextRight>
              <FooterTextRight style={{fontWeight: "700"}}><img src={MailIcon} alt="MailIcon" /> example@mail.com</FooterTextRight>
            </Grid>
          </Grid>
          <FooterDivider>
            <Typography style={{...CustomFontVariant.palette.font14400, lineHeight: "22px"}}>
              Copyright <img src={CopyRight} alt="CopyRight" style={{width: "11px"}} /> 2024 iusedtolivehere.com - All Rights Reserved.
            </Typography>
          </FooterDivider>
        </AboutUsFooter>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MissionContainer = styled("div")({
  height: '328px',
  width: "100%",
  backgroundColor: '#EAE7DC'
});

const MissionTitle = styled(Typography)({
  fontSize: 36,
  fontWeight: 700,
  fontFamily: "Open Sans",
  lineHeight: "44px",
  height: "100%",
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
});

const MissionText = styled(Typography)({
  fontSize: 24,
  fontWeight: 400,
  fontFamily: "Open Sans",
  lineHeight: "32px",
  height: "100%",
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
  width: "80%"
});

const AboutUsContent = styled("div")({
  height: '610px',
  display: 'flex',
  flexDirection: "column",
  gap: "75px"
});

const AboutUsFooter = styled("div")({
  height: '332px',
  width: "100%",
  backgroundColor: '#EAE7DC',
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
});

const ContentTitle = styled(Typography)({
  fontSize: 36,
  fontWeight: 700,
  fontFamily: "Open Sans",
  lineHeight: "44px",
  height: "100%",
  marginBottom: "10px",
});

const ContentText = styled(Typography)({
  fontSize: 24,
  fontWeight: 400,
  fontFamily: "Open Sans",
  lineHeight: "32px",
  height: "100%",
  display: 'flex',
  justifyContent: "center",
  alignItems: "center",
  width: "654px"
});

const FooterText = styled(Typography)({
  fontSize: 18,
  fontWeight: 400,
  fontFamily: "Open Sans",
  lineHeight: "26px",
  marginBottom: "15px"
});

const FooterTextRight = styled(Typography)({
  fontSize: 16,
  fontWeight: 400,
  fontFamily: "Open Sans",
  lineHeight: "24px",
  marginBottom: "15px",
  display: "flex",
  gap: "10px"
});

const Divider = styled("div")({
  borderBottom: `1px solid #E2E8F0`,
  width: "50%"
});

const DividerBlock = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "70px"
})

const FooterDivider = styled("div")({
  borderTop: `1px solid #0000004D`,
  width: "53%",
  height: "46px",
  position: "relative",
  top: 60,
  display: "flex",
  alignItems: "end",
  justifyContent: "center",
});
// Customizable Area End
