import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleSubscribe?: () => void;
  isSubscribe?: boolean;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isBilling: boolean;
  isPayment: boolean;
  isDeleteOpen: boolean;
  cardDetails: any[];
  isAddCard: boolean;
  showCVV: boolean;
  cardName: string;
  cardNumber: string;
  expiryDate: string;
  cvv: string;
  isEdit: boolean;
  cardFormError: {
    cardName: string,
    cardNumber: string,
    expiryDate: string,
    cvv: string;
  };
  isPay: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Subscriptionbilling2Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isBilling: false,
      isPayment: false,
      isDeleteOpen: false,
      cardDetails: [],
      isAddCard: false,
      showCVV: false,
      cardName: "",
      cardNumber: "",
      expiryDate: "",
      cvv: "",
      isEdit: false,
      cardFormError: {
        cardName: "",
        cardNumber: "",
        expiryDate: "",
        cvv: "",
      },
      isPay: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount(){
    this.setState({cardDetails: [{
      cardNumber: "****8239" 
    }]})
  }
  CardDetails = [
    {
      cardTitle: "Premium",
      badgeText: "Inactive",
      cardSubtitle: "Perfect plan for Premium",
      cardText:  ["Get an ad free experience.", "Add years for unlimited properties.", "Search and view unlimited properties."],
      cardAmount: "1,080"
    },
    {
      cardTitle: "Free",
      badgeText: "Active",
      cardSubtitle: "Perfect plan for Free",
      cardText:  ["Get an ad free experience.", "Add years for up to 5 properties.", "Search and view unlimited properties."],
      cardAmount: "0,000"
    }
  ]

  BillingTableHeader = [
    "Amount", "Type of Plan", "Payment date", "Card used to pay", ""
  ]

  BillingTableBody = [
    {
      amount: "1,080.00",
      plan: "Professional Plan",
      date: "Apr 14, 2022",
      card: "****8239",
      text: "Receipt"
    },
    {
      amount: "1,080.00",
      plan: "Professional Plan",
      date: "Apr 14, 2022",
      card: "****8239",
      text: "Receipt"
    },
    {
      amount: "1,080.00",
      plan: "Professional Plan",
      date: "Apr 14, 2022",
      card: "****8239",
      text: "Invoice"
    }
  ];

  validateForm = () => {
    const newErrors = { cardNumber: '', cardName: '', expiryDate: '', cvv: '' };

    if (!this.state.cardNumber.trim()) {
      newErrors.cardNumber = 'Card number is required.';
    } else if (!/^\d*$/.test(this.state.cardNumber)) {
      newErrors.cardNumber = 'Card number only contain digits.';
    }

    if (!this.state.cardName.trim()) {
      newErrors.cardName = 'Card name is required.';
    } else if (!/^[a-zA-Z]*$/.test(this.state.cardName)) {
      newErrors.cardName = 'Card name only contain alphabets.';
    }

    if (!this.state.expiryDate.trim()) {
      newErrors.expiryDate = 'Expiry date is required.';
    } else if (!/^(0[1-9]|1[0-2])\/\d{0,2}$/.test(this.state.expiryDate)) {
      newErrors.expiryDate = 'Expiry date is not valid.';
    }

    if (!this.state.cvv.trim()) {
      newErrors.cvv = 'CVV is required.';
    } else if (!/^\d*$/.test(this.state.cvv)) {
      newErrors.cvv = 'CVV must contain only digits.';
    }

    this.setState({cardFormError: newErrors})
    return !newErrors.cardNumber && !newErrors.cardName && !newErrors.expiryDate && !newErrors.cvv;
  };

  handleChange = (event: any, name: string) => {
    if(name === "expiryDate") {
      if (/^\d{2}$/.test(event.target.value)) {
        event.target.value = event.target.value + "/";
      }
  
    }
    
    this.setState((prevState) => ({
      ...prevState,
      [name]: event.target.value,
      cardFormError: {
        ...prevState.cardFormError, // Keep existing fields intact
        [name]: "", // Update the name field
      },
    }));
  };

  handleBackBtn = () => {
    this.setState({isBilling: false, isPayment: false})
  }

  deleteCardDetails = () => {
    this.setState({isDeleteOpen: false})
  };

  handleEdit = () => {
    this.setState({ isEdit: true })
  };

  handleDelete = () => {
    this.setState({ isDeleteOpen: true })
  }
  // Customizable Area End
}
